import React, { useEffect, useState } from "react";
import {
  Form,
  Row,
  Col,
  Button,
  notification,
  Modal,
  Result,
  Checkbox,
} from "antd";
import axios from "axios";
import { useLocation, useNavigate } from "react-router-dom";
//Image
import DoryLogo from "../assets/logo.jpg";
//Hooks
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { useForm } from "react-hook-form";
// Components
import renderInput from "../component/inputForm";
import phil from "../external_node_modules/philippine-location-json-for-geer";
import Axios from "../helpers/axios";
import subOpTermsAndCondition from "../assets/SUB-OPERATOR-CONTRACT.pdf";

import {
  LoadingOutlined,
  CheckCircleOutlined,
  CloseCircleOutlined,
} from "@ant-design/icons";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const SubOperatorForm = ({ role = {} }) => {
  const schema = yup.object().shape({
    full_name: yup.string().required("Fullname is a required field"),

    province: yup.string().required("Province is required field"),
    city: yup.string().required("City is required field"),
    barangay: yup.string().required("Barangay is required"),

    password: yup.string().required("Password is required"),
    service_province: yup.string().required("Service province is required"),
    service_city: yup.string().required("Service City Required"),
    username: yup
      .string()
      .required("Username is required field")
      .matches(/^\S*$/, "Username cannot contain spaces"),
    email: yup
      .string()
      .required("Email is required field")
      .email("Email must be a valid email")
      .matches(/^\S*$/, "Email cannot contain spaces"),
    contact_number: yup
      .string()
      .required("Contact number is required field")
      .matches(
        /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/,
        "Phone number is not valid"
      )
      .min(11, "too short")
      .max(11, "too long"),
  });

  let defaultValues = {
    full_name: "",
    contact_number: "",
    province: "",
    city: "",
    barangay: "",
    username: "",
    password: "",
    service_province: "",
    service_city: "",
    email: "",
  };
  const navigate = useNavigate();

  const query = useQuery();
  const referralCode = query.get("ref");
  const [cities, setCities] = useState([]);
  const [barangays, setBarangays] = useState([]);
  const [selectedMunicipality, setSelectedMunicipality] = useState([]);
  const [scopeAreaCity, setScopeAreaCity] = useState([]);
  const [type, setType] = useState("Sub_operator");
  const [limitChecker, setLimitChecker] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [checkLimitLoading, setCheckLimitLoading] = useState(false);
  const [serviceArea, setServiceArea] = useState("");
  console.log("cities : >>> ", cities);
  const [acceptedTermsAndCondition, setAcceptedTermsAndCondition] =
    useState(false);
  const {
    control,
    handleSubmit,
    formState: { errors },
    setValue,
    reset,
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues,
    mode: "onSubmit",
    reValidateMode: "onBlur",
  });

  const onCheck = (e) => {
    setAcceptedTermsAndCondition(!acceptedTermsAndCondition);
  };
  const openNotification = (message) => {
    notification.success({
      message: message,
    });
  };
  const subOperatorLimitChecket = async (data) => {
    try {
      setCheckLimitLoading(true);

      const response = await Axios().get(
        `/api/sub-operator/limit-checker/${data.service_city}/${data.service_province}`
      );

      if (response.data.isLimitMax !== true) {
        setCheckLimitLoading(false);
        reset(defaultValues);
        createTempData(data);
        setLimitChecker(false);
      } else {
        setLimitChecker(true);
      }

      console.log("res : >>> ", data);
    } catch (error) {
      console.log("subOperatorLimitChecket  :: >>>> ", error);
    }
  };

  const createTempData = async (data) => {
    try {
      const response = await Axios().post(`/api/sub-operator`, {
        password: data.password,
        username: data.username,
        barangay: data.barangay,
        city: data.city,
        province: data.province,
        email: data.email,
        contact_number: data.contact_number,
        full_name: data.full_name,
        referral_code: data.referral_code,
        service_city: data.service_city,
        service_province: data.service_province,
        type: data.type,
        partner_group_areas: null,
        prov_code: data.prov_code,
      });

      if (response.data.message !== undefined) {
        reset(defaultValues);
        window.location.href = response.data.paymentLink;
      }
    } catch (error) {
      console.log("createTempData : >>> ", error);
    }
  };

  const onSubmit = async (values) => {
    try {
      const province = phil.provinces.filter(
        (province) => province.prov_code === values.province
      )[0];
      const city = phil.city_mun.filter(
        (city) => city.mun_code === values.city
      )[0].name;
      const barangay = phil.barangays.filter(
        (barangay) => barangay.brgy_code === values.barangay
      )[0].name;
      const ServiceProvince = phil.provinces.filter(
        (province) => province.prov_code === values.service_province
      )[0].name;
      const ServiceCity = phil.city_mun.filter(
        (city) => city.mun_code === values.service_city
      )[0].name;

      let filteredSelectedMunicipality = [];
      selectedMunicipality
        .filter((d) => d !== "")
        .map((dd) => filteredSelectedMunicipality.push(dd));

      const createData = {
        full_name: values.full_name,
        contact_number: values.contact_number,
        username: values.username,
        password: values.password,
        province: province.name,
        city: city,
        barangay: barangay,
        type: role,
        partner_group_areas:
          selectedMunicipality.length === 0
            ? JSON.stringify([city])
            : JSON.stringify(filteredSelectedMunicipality),
        prov_code: values.province,
        service_province: ServiceProvince,
        service_city: ServiceCity,
        referral_code: referralCode,
        email: values.email,
      };
      setServiceArea(ServiceCity);
      setIsModalOpen(true);
      subOperatorLimitChecket(createData);
    } catch (error) {
      console.log("onSubmit", error);
    }
  };

  const onChangeProvince = (prov_code) => {
    setValue("city", "");
    setValue("barangay", "");
    setCities(
      phil
        .getCityMunByProvince(prov_code)
        .filter(
          (d) =>
            d.name !== "ALICIA" &&
            d.name !== "ROXAS" &&
            d.name !== "DAANBANTAYAN"
        )
    );
    setBarangays([]);
  };

  const onChangeScopeAreaProvince = (prov_code) => {
    setValue("service_city", "");
    setScopeAreaCity(phil.getCityMunByProvince(prov_code));
  };

  const onChangeCity = (mun_code) => {
    setValue("barangay", "");
    setBarangays(phil.getBarangayByMun(mun_code));
  };

  return (
    <div>
      <div style={{ display: "flex", justifyContent: "center" }}></div>
      <p style={{ fontSize: 16, fontWeight: "bold" }}>Personal Information</p>
      <Form
        layout="vertical"
        initialValues={{
          requiredMarkValue: true,
        }}
        requiredMark={true}
        onFinish={handleSubmit(onSubmit)}
        encType="multipart/form-data"
      >
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            {renderInput(
              {
                label: "Full name",
                name: "full_name",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {renderInput(
              {
                label: "Email",
                name: "email",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {renderInput(
              {
                label: "Contact number",
                name: "contact_number",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>

          <Col className="gutter-row" span={24}>
            <div>
              <p style={{ fontSize: 16, fontWeight: "bold" }}>
                Your Address Details
              </p>
            </div>
            {renderInput(
              {
                label: "Province",
                name: "province",
                errors: errors,
                required: "true",
                type: "select",
                options: phil.provinces,
                valueKey: "prov_code",
                valueText: "name",
                onChangeOutside: onChangeProvince,
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "City",
                name: "city",
                errors: errors,
                required: "true",
                type: "select",
                options: cities,
                valueKey: "mun_code",
                valueText: "name",
                onChangeOutside: onChangeCity,
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Barangay",
                name: "barangay",
                errors: errors,
                required: "true",
                type: "select",
                options: barangays,
                valueKey: "brgy_code",
                valueText: "name",
                onChangeOutside: () => {},
              },
              control
            )}
          </Col>
        </Row>
        <p style={{ fontSize: 16, marginLeft: 5, fontWeight: "bold" }}>
          Create username and password
        </p>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={12}>
            {renderInput(
              {
                label: "Username",
                name: "username",
                errors: errors,
                required: "true",
              },
              control
            )}
          </Col>
          <Col className="gutter-row" span={12}>
            {renderInput(
              {
                label: "Password",
                name: "password",
                errors: errors,
                required: "true",
                type: "password",
              },
              control
            )}
          </Col>
        </Row>
        <p style={{ fontSize: 16, marginLeft: 5, fontWeight: "bold" }}>
          Area You Want to Lock Up
        </p>
        <Row gutter={{ xs: 8, sm: 16, md: 24, lg: 32 }}>
          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Province",
                name: "service_province",
                errors: errors,
                required: "true",
                type: "select",
                options: phil.provinces,
                valueKey: "prov_code",
                valueText: "name",
                onChangeOutside: onChangeScopeAreaProvince,
              },
              control
            )}
          </Col>

          <Col className="gutter-row" span={24}>
            {renderInput(
              {
                label: "Service City",
                name: "service_city",
                errors: errors,
                required: "true",
                type: "select",
                options: scopeAreaCity,
                valueKey: "mun_code",
                valueText: "name",
              },
              control
            )}
          </Col>
        </Row>
        <br />

        <div>
          <Checkbox onChange={onCheck}>
            {"I have read and agree to the"}
            <a
              href={subOpTermsAndCondition}
              target="_blank"
              rel="noopener noreferrer"
            >
              {" "}
              terms of service
            </a>
          </Checkbox>
        </div>

        <br />
        <div className="text-right mt-5">
          <Button
            disabled={!acceptedTermsAndCondition}
            style={{
              width: "100%",
              backgroundColor: !acceptedTermsAndCondition
                ? "#AEAEAE"
                : "#00BF63",
            }}
            htmlType="submit"
            type="primary"
          >
            {
              <p
                style={{
                  fontSize: 18,
                  color: !acceptedTermsAndCondition ? "gray" : "white",
                }}
              >
                Submit
              </p>
            }
          </Button>
        </div>
      </Form>

      <Modal
        open={isModalOpen}
        onCancel={() => setIsModalOpen(!isModalOpen)}
        width={660}
        footer={[]}
      >
        <div>
          {referralCode !== null ? (
            <>
              {checkLimitLoading ? (
                <div
                  style={{
                    flexDirection: "column",
                    gap: 10,
                    alignItems: "center",
                    justifyContent: "center",
                    display: "flex",
                  }}
                >
                  <LoadingOutlined style={{ fontSize: 89, color: "#1E90FF" }} />
                  <p style={{ textAlign: "center", fontSize: 18 }}>
                    Wait while the system is checking if the selected service
                    area still available.{" "}
                  </p>
                </div>
              ) : (
                <Result
                  status="success"
                  title={
                    <>
                      <div
                        style={{
                          flexDirection: "column",
                          gap: 10,
                          alignItems: "center",
                          justifyContent: "center",
                          display: "flex",
                        }}
                      >
                        <LoadingOutlined
                          style={{ fontSize: 20, color: "#1E90FF" }}
                        />
                        <p>{serviceArea.toUpperCase()} is available.</p>
                        <p style={{ textAlign: "center", fontSize: 18 }}>
                          Please wait we are redirecting you to the payment area
                        </p>
                      </div>
                    </>
                  }
                />
              )}{" "}
            </>
          ) : (
            <div>
              <Result status="error" title="Referral Code Is Invalid"></Result>
            </div>
          )}
          {limitChecker && (
            <div
              style={{
                flexDirection: "column",
                gap: 10,
                alignItems: "center",
                display: "flex",
              }}
            >
              <CloseCircleOutlined style={{ fontSize: 90, color: "tomato" }} />
              <p style={{ textAlign: "center", fontSize: 18 }}>
                Sub operator in {serviceArea.toUpperCase()} reached its limit
                please choose another munipality.
              </p>
            </div>
          )}
        </div>
      </Modal>
    </div>
  );
};

export default SubOperatorForm;
