import "./App.css";

import {
  BrowserRouter as Router,
  Routes,
  Route,
  Link,
  Navigate,
} from "react-router-dom";
import Main from "./Pages/Main";
import SuccessPage from "./Pages/successPage";
import FailPage from "./Pages/failPage";
function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Main />} />
        <Route path="/success-page" element={<SuccessPage />} />
        <Route path="/fail-page" element={<FailPage />} />
      </Routes>
    </Router>
  );
}

export default App;
