import React, { useEffect, useState } from "react";
import { Image, Card, Button, Row, Col } from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import DoryLogo from "../assets/logo.jpg";
import SubOperatorForm from "./SubOperatorForm";
import OperatorForm from "./OperatorForm";
import { useLocation } from "react-router-dom";
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
const Main = () => {
  const query = useQuery();
  const signUpStype = query.get("type");
  const [type, setType] = useState(null);

  const handleBack = () => setType(null);

  useEffect(() => {
    if (signUpStype === "ops") {
      setType("operator_admin");
    }
    if (signUpStype === "sub_ops") {
      setType("sub_operator");
    }
  }, [signUpStype]);

  return (
    <div
      style={{
        backgroundColor: "#00BF63",
      }}
    >
      <div
        style={{
          display: "flex",
          justifyContent: "center",
          alignItems: "flex-start",
          minHeight: "100vh",
          padding: "0 10px",
          boxSizing: "border-box",
          backgroundColor: "#00BF63",
        }}
      >
        <Card
          style={{
            width: "100%",
            maxWidth: 700,
            marginTop: "20px",
            marginBottom: "20px",
            position: "relative",
            paddingBottom: "40px",
          }}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              flexDirection: "column",
              gap: -10,
            }}
          >
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
                justifyContent: "center",
                marginBottom: "2px",
              }}
            >
              <Image
                preview={false}
                src={DoryLogo}
                style={{ width: 90, height: 90 }}
              />
              <p style={{ fontSize: 30, fontWeight: "bold" }}>
                {type
                  ? `Sign Up As ${
                      type === "sub_operator" ? "Sub Operator" : "City Operator"
                    }`
                  : "Sign Up"}
              </p>
            </div>

            {type ? (
              <>
                {type === "sub_operator" && <SubOperatorForm role={type} />}
                {type === "operator_admin" && <OperatorForm role={type} />}
              </>
            ) : (
              <Row gutter={16} justify="center">
                <Col>
                  <Button
                    type="default"
                    style={{
                      width: 200,
                      height: 100,
                      fontSize: 18,
                      fontWeight: "bold",
                      marginBottom: "2px",
                    }}
                    onClick={() => setType("sub_operator")}
                  >
                    Sub Operator
                  </Button>
                </Col>
                <Col>
                  <Button
                    type="default"
                    style={{
                      width: 200,
                      height: 100,
                      fontSize: 18,
                      fontWeight: "bold",
                      marginBottom: "2px",
                    }}
                    onClick={() => setType("operator_admin")}
                  >
                    Operator
                  </Button>
                </Col>
              </Row>
            )}
          </div>
        </Card>
      </div>
    </div>
  );
};

export default Main;
